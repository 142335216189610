import store from '../../../../../../store';
export default {
  name: 'approvalHistory',
  props: ['wfHeaderId', 'eventId', 'invoiceDetails', 'formName'],
  data() {
    return {
      wfEventId: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      historyList: [],
      historyFields: [
        {
          key: 'wf_inprocess_hdr_id',
          label: 'Aprroval Batch'
        },
        {
          key: 'approver_name'
        },
        { key: 'approver_designation', label: 'Designation' },
        {
          key: 'temp_approved_amount'
        },
        {
          key: 'action_date'
        },
        {
          key: 'initiation_date'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments'
        },
        {
          key: 'consultation_comments'
        },
        {
          key: 'information_only',
          lable: 'Info Only'
        },
        {
          key: 'version'
        },
        {
          key: 'notify'
        }
      ],
      historyRemList: [],
      reminderFileds: [
        {
          key: 'approver_email_id',
          label: 'Approver Email'
        },
        {
          key: 'approver_name'
        },
        {
          key: 'notified_date'
        },
        {
          key: 'notify_by'
        }
      ],
      showDelegateModal: false,
      showValueSetModal: false,
      allFlag: false,
      delegation: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    if (
      this.$route.name === 'partyOnBoard' ||
      this.$route.name === 'invoiceApproval' ||
      this.formName === 'vendorInvoice' ||
      this.formName === 'purReq' ||
      this.formName === 'provisions'
    ) {
      this.historyFields = [
        {
          key: 'approver_name'
        },
        { key: 'approver_designation', label: 'Designation' },
        {
          key: 'action_date'
        },
        {
          key: 'seq_order',
          label: 'Approver Order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments'
        }
      ];
    }
    if (this.eventId) {
      this.getHistory(this.eventId);
      this.getHistoryReminder(this.eventId);
    } else {
      this.getEventId();
    }
  },
  methods: {
    switchVariable() {
      this.allFlag = this.allFlag
        ? (this.allFlag = false)
        : this.allFlag == false
        ? (this.allFlag = true)
        : null;
      if (this.eventId) {
        this.getHistory(this.eventId);
      } else {
        this.getEventId();
      }
    },
    showAllHistory() {
      this.getHistory(this.eventId);
    },
    showHideDelegateModal(flag) {
      this.showDelegateModal = flag;
      if (!flag) {
        this.delegation.text = null;
        this.delegation.value = null;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.delegation.text) {
        this.delegation.text = null;
        this.delegation.value = null;
      }
    },
    delegateEmployee() {
      const payload = {
        emp_id: this.delegation.value,
        wf_dtl_id: this.invoiceDetails.wf_inprocess_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('workflow/delegateEmployee', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            alert('Delegation Successfully');
            this.getHistory(this.eventId);
            this.getHistoryReminder(this.eventId);
            this.showDelegateModal = false;
            this.$emit('closeModal');
          } else {
            alert(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'EMPL_INV_DEL') {
        if (item.value_set_dtl_id == this.invoiceDetails.emp_id) {
          alert('You cannot delegate to your self');
        } else {
          this.delegation.text = item.value_code;
          this.delegation.value = item.value_set_dtl_id;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getHistory(eventId) {
      const payload = {
        eventId: eventId,
        nfaHdrId: this.wfHeaderId,
        approvalStatus: this.allFlag ? 'Y' : !this.allFlag ? 'N' : ''
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getApprovalList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.historyList = response.data.data;
            // if (this.invoiceDetails) {
            //   const filterApprovalStatus = this.historyList.filter(elem => {
            //     if (elem.approval_status == 'INPROCESS') {
            //       return elem;
            //     }
            //   });
            //   const approverId = filterApprovalStatus.map(
            //     data => data.approver_employee_id
            //   );
            //   const approverMatch = approverId.includes(
            //     this.invoiceDetails.emp_id
            //   );
            //   if (approverMatch) {
            //     this.historyFields.unshift({
            //       key: 'delegation'
            //     });
            //   }
            // }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHistoryReminder(eventId) {
      const payload = {
        menuId: eventId,
        recordId: this.wfHeaderId
      };
      this.$store
        .dispatch('intWorkflow/getReminderHitsory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.historyRemList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    sendNotification(item) {
      const payload = {
        approver_emp_id: item.approver_employee_id,
        comm_type_vset: 'SENDGRID',
        event_id: this.wfEventId ? this.wfEventId : this.eventId,
        record_id: this.wfHeaderId
      };
      this.loader = true;
      this.$store
        .dispatch('workflow/sendNotification', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
            this.getHistoryReminder(this.wfEventId);
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.wfEventId = response.data.data;
            this.getHistory(this.wfEventId);
            this.getHistoryReminder(this.wfEventId);
          }
        });
    }
  }
};
